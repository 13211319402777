<template>
  <div>
    <b-modal
      id="multiple-product-selection-modal"
      title="Please select"
      hide-footer
    >
      <selection-cards
        :items="availableProducts"
        @chosen="productChosen"
      />
    </b-modal>
    <b-modal
      id="product-confirm-modal"
      hide-footer
      hide-header
      size="lg"
    >
      <bundle-checkout
        v-if="selectedProduct?.product_bundle_override"
        :bundle="productBundleOverride"
        :company="company"
        @product-added="productBundleOverrideAdded"
      />
      <filing-options
        v-else-if="productKind === 'filing_product'"
        submission-label="Add"
        :product="selectedProduct"
        :company="company"
        :disable-domestic-form="true"
        :disable-registered-agent-addon="!enableRegisterAgentAddOn"
        :saved-items="[]"
        :monitoring-product="monitoringProduct"
        @monitoringProductAdded="monitoringProductChosen"
        @submit="addFilingToCart"
        @closeModal="closeProductOptionsModel()"
      />
      <simple-checkout
        v-else-if="productKind === 'simple_product'"
        :product="selectedProduct"
        :company="company"
        @completed="addProductToCart"
      />
      <ct-centered-spinner v-if="loading" />
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { getFieldSuggestionValue } from '@/components/StagelineV2/schemaForm/pdf/helper'
import { addBoiWithFilingProduct, addRenewalWithFilingProduct } from '@/components/HireUs/helper'

export default {
  name: 'ProductOptionsConfirmModal',
  components: {
    BundleCheckout:    () => import ('@/components/HireUs/BundleCheckout.vue'),
    FilingOptions:     () => import('@/components/HireUs/FilingOptions'),
    SimpleCheckout:    () => import('@/components/HireUs/SimpleCheckout'),
    SelectionCards:    () => import('@/components/shared/SelectionCards'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
  },
  props: {
    loading: Boolean,
    bus: Object,
  },
  data() {
    return {
      availableProducts: [],
      selectedProduct: null,
      optionalProducts: [],
      monitoringProductSelected: true,
      monitoringProduct: '',
      bundleItems: {},
      productBundleOverride: {},
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'pdfViewerAgencyResource',
      'company',
    ]),
    ...mapGetters('jurisdictions', [
      'findByAbbreviation',
    ]),
    ...mapGetters('checkout', [
      'findCartItem',
      'currentBundleItems',
      'registeredAgentProductByJurisdiction',
      'deduceCartItemJurisdiction',
      'renewalService',
      'boiFilingInCart',
      'boiFiling',
      'findProductBy',
    ]),
    ...mapGetters('companies', [
      'hasExistingRAServiceInJurisdiction',
      'currentCompany',
    ]),
    ...mapGetters('stagelineSchemaForm', [
      'formData',
      'resource',
      'resourceFields',
    ]),
    ...mapGetters('products', [
      'optionalCategories',
    ]),
    ...mapGetters('monitoring', [
      'monitoringTrialEligible',
      'monitoringServiceActive',
    ]),
    productKind() {
      return this.selectedProduct?.kind
    },
    enableRegisterAgentAddOn() {
      return ['form a company', 'register a company'].includes(this.selectedProduct?.filing_name) &&
        this.selectedProduct?.filing_sub_name !== 'efile form a company'
    },
  },
  async mounted() {
    await this.determineTrialEligibilityMonitoring(this.currentCompany.id)

    if (this.monitoringTrialEligible) {
      this.monitoringProduct =  await this.loadProductByCategory('monitoring')
    }
    this.bus.$on('open-product-options-confirm', this.open)
  },
  methods: {
    ...mapActions('checkout', [
      'addToCart',
      'removeFromCart',
      'addFreeTaxIdToCart',
      'checkForRACartItemAndRemove',
      'addRegisteredAgentAddOn',
      'addBundleToCart',
      'checkForBundleFreeTrialsAndCalculateTotal',
    ]),
    ...mapActions('stageline', [
      'setFormationType',
    ]),
    ...mapActions('stagelineSchemaForm', [
      'loadResourceFieldSchema',
      'loadResourceFieldsForCompany',
      'setResource',
      'setFormValue',
      'updateFormData',
      'processFormData',
    ]),
    ...mapActions('products', [
      'loadProductByCategory',
    ]),
    ...mapActions('monitoring', [
      'determineTrialEligibilityMonitoring',
    ]),
    ...mapActions('freeTrials', [
      'determineTrialEligibilityBatch',
    ]),
    // Find and add monitoring product page
    async monitoringProductChosen(isSelected) {
      this.monitoringProductSelected = isSelected
    },
    async open(products) {
      this.availableProducts = products
      if (!products || products.length === 0) return

      this.selectedProduct = null
      if (this.availableProducts.length > 1) {
        this.$bvModal.show('multiple-product-selection-modal')
      } else {
        await this.productChosen(this.availableProducts[0])
      }
    },
    productChosen(product) {
      this.selectedProduct = product
      if (this.selectedProduct?.product_bundle_override) {
        this.productBundleOverride = this.findProductBy('product_bundle', {
          id : this.selectedProduct.product_bundle_override.id,
        }) || {}
      }
      this.selectedProduct.skipResolveOptionalItems = true //Need this set early for simple-checkout
      this.$bvModal.hide('multiple-product-selection-modal')

      if (product.kind === 'filing_product' || product.kind === 'simple_product') {
        this.$bvModal.show('product-confirm-modal')
      } else {
        this.addProductToCart()
      }
    },
    async addFilingToCart(info) {
      const product = info.product
      const jurisdiction = info.jurisdiction
      const filingMethod = info.filingMethod
      const addProduct = {
        ...product,
        filingMethodId: filingMethod.id,
        jurisdiction: jurisdiction,
      }

      // Add monitoring product to the cart if product is selected
      if (
        product.filing_name === "trademark" &&
        this.monitoringProductSelected &&
        this.monitoringProduct.show_to_client &&
        !this.monitoringServiceActive
      ) {
        await this.addToCart(this.monitoringProduct)
      }

      await this.addProductToCart(addProduct)
    },
    async addProductToCart(addProduct = this.selectedProduct) {
      switch (this.selectedProduct.kind) {
        case 'simple_product': {
          //Already added to cart in simple-checkout
          this.$bvModal.hide('product-confirm-modal')
          this.logProductAdded(addProduct)
          this.complete()
          return
        }
        case 'filing_product': {
          this.$bvModal.hide('product-confirm-modal')
          break
        }
        case 'product_bundle': {
          addProduct = await this.checkForBundleFreeTrialsAndCalculateTotal({
            productBundle: addProduct,
          })
        }
      }

      const addedToCart = this.selectedProduct.kind === 'product_bundle' ?
        await this.addBundleToCart({
          bundle: {
            ...addProduct,
            data: this.currentBundleItems,
          },
        }) :
        await this.addToCart(addProduct)
      // Address situations where the item is not added to cart due to Smart/Stupid Cart Logic
      if (addedToCart) {
        this.logProductAdded(addProduct)
        if (this.selectedProduct.filing_name === 'form a company') await this.setFormationType('online')

        const cartItemProduct = this.findCartItem(this.selectedProduct.id)
        const jurisdiction = this.deduceCartItemJurisdiction(cartItemProduct)

        const renewalAdded = await addRenewalWithFilingProduct({
          filingProduct: this.selectedProduct,
          company: this.currentCompany,
          jurisdiction: jurisdiction,
          productCartItemId: cartItemProduct.id,
          skipResolveOptionalItems: true,
        })

        if (renewalAdded) {
          this.logProductAdded({
            ...this.renewalService,
            parentItemId: cartItemProduct.id,
          })
        }

        if (this.enableRegisterAgentAddOn) {
          if (!this.hasExistingRAServiceInJurisdiction(jurisdiction)) {
            await this.addRegisteredAgentAddOn({
              parentItemId: cartItemProduct.id,
              jurisdiction: jurisdiction,
            })

            this.logProductAdded({
              name: 'Registered Agent Service',
              jurisdiction: jurisdiction,
              parentItemId: cartItemProduct.id,
            })
          }

          await this.updateAndProcessRAFormDataWithPrefillSuggestions()
        }

        const boiAdded = await addBoiWithFilingProduct(this.selectedProduct)
        if (boiAdded) {
          this.logProductAdded({
            name: 'Beneficial Ownership Information Report',
            ...this.boiFiling,
          })
        }

        if (this.selectedProduct.filing_name === 'free form a company') {
          await this.addFreeTaxIdToCart(cartItemProduct)
          await this.checkForRACartItemAndRemove(this.deduceCartItemJurisdiction(cartItemProduct))
        }

        this.complete()
      }
    },
    async productBundleOverrideAdded() {
      const productAdded = this.selectedProduct.product_bundle_override
      await this.setFormationType('online')
      this.$bvModal.hide('product-confirm-modal')
      this.logProductAdded(productAdded)
      this.complete()
    },
    async updateAndProcessRAFormDataWithPrefillSuggestions() {
      if (_.isEmpty(this.resource)) {
        this.setResource(this.pdfViewerAgencyResource)
      }
      if (this.resource && _.isEmpty(this.formData)) {
        await this.loadResourceFieldsForCompany({
          companyId: this.currentCompany.id,
          resourceId: this.resource.id,
        })
      }
      if (this.resource && this.resourceFields.length === 0) {
        await this.loadResourceFieldSchema({
            resourceId: this.resource.id,
        })
      }
      const fieldsWithCurrentSuggestion = this.resourceFields?.length ?
        this.resourceFields.filter(rf =>
          rf.type === 'object' &&
          rf?.meta?.suggestion?.context?.product_kind === 'registered_agent_product'
        ) : []
      if (fieldsWithCurrentSuggestion.length > 0) {
        fieldsWithCurrentSuggestion.forEach(field => {
          if (field?.data?.parts?.length) {
            this.resourceFields.find(f => f.id === field.id).data.disabled = true
            this.setFormValue({
              key: field.id,
              value: getFieldSuggestionValue(field),
            })
          }
        })
        await this.updateFormData()
        await this.processFormData()
      }
    },
    complete() {
      this.$emit('complete')
    },
    logProductAdded(productAdded) {
      this.$emit('productAdded', productAdded)
    },
    closeProductOptionsModel() {
      this.$bvModal.hide('product-confirm-modal')
    },
  },
}
</script>

<style lang="scss">

// everything below targets non-stageline components ↴

// SelectionCards.vue
div.multiple-product-list {

  div.multiple-product-selection {

    div.main-right {

      a.choice {
        color: $ct-ui-primary !important;
      }
    }
  }
}

// FilingOptions.vue
div.border-bottom {

  div.custom-control {

    input.custom-control-input:checked ~ label.custom-control-label::before {
      background: $ct-ui-primary !important;
      border-color: $ct-ui-primary !important;
    }

    input.custom-control-input:checked ~ label.custom-control-label::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }
  }
}

div.lower-container {

  button.btn-primary {
    background: $ct-ui-primary !important;
    border-color: $ct-ui-primary !important;
  }
}

// SimpleCheckout
div.card-body {

  button.btn-primary {
    background: $ct-ui-primary !important;
    border-color: $ct-ui-primary !important;

    &:hover {
      background: $ct-ui-primary-dark !important;
      border-color: $ct-ui-primary !important;
    }
  }
}
</style>
